import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import Image from 'common/components/Image';
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
} from 'containers/shared/imageleft/humanityBlock.style';
import ViewsManagementImage from './views-management.png';

const HumanityBlock = ({ row, col }) => {
  return (
    <BlockWrapper id="socialFundraising">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col image-container" {...col}>
            <ImageWrapper>
              <Image
                src={ ViewsManagementImage }
                alt="Views on management"
              />
            </ImageWrapper>
          </Box>
          <Box className="col text-container" {...col}>
            <ContentWrapper>
              <Heading content="Views on Management" />
              <p>
                Over 50% of respondents were positive (i.e. gave ranks of one or two) for each potential management
                practice, and over 70% gave ranks of one or two for ongoing management as a floodplain
                and continued habitat management.
              </p>
              <p>
                Respondents were slightly more ambivalent (i.e. gave
                ranks of three or four) or negative (i.e. gave ranks of five and six) about the remaining four
                categories although the majority were still positive in each case.
              </p>
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // HumanityBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default HumanityBlock;
