import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Image from 'common/components/Image';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
} from './promotionBlock.style';
import ModeTransportImage from './mode-transport.png';

const PromotionBlock = ({ row, col }) => {
  return (
    <BlockWrapper id="ourCommunity">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col text-container" {...col}>
            <ContentWrapper>
              <Heading content="How people visit" />
              <p>
                Most people arrived at the Lammas Lands on foot. The largest minority
                arrived by car/van, a few by bicycle and just a handful arriving by bus and train and also
                boat (which was specified by one respondent under “other”).
              </p>
              <p>The most frequently given reason for visiting the Lammas Lands was scenery (34.8%),
                followed by rural feel (19.6%).</p>
            </ContentWrapper>
          </Box>
          <Box className="col image-container" {...col}>
            <ImageWrapper>
              <Image src={ ModeTransportImage } alt="Mode of Transport to Lammas Lands" />
            </ImageWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// PromotionBlock style props
PromotionBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// PromotionBlock default style
PromotionBlock.defaultProps = {
  // PromotionBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // PromotionBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default PromotionBlock;
