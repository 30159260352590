import React from 'react';
import LeftBar from './leftBar';
import BannerWrapper, {
  ContentWrapper,
  TextArea,
  ImageArea,
} from 'containers/shared/banner/bannerSection.style';

import BannerImage from './banner-image.jpg';

const BannerSection = () => {
  return (
    <BannerWrapper>
      <LeftBar text="SCROLL DOWN" offset={81} sectionId="#feature" />
      <ContentWrapper>
        <TextArea>
          <h2>
            Visitor Demographics <br /><small>Lammas Lands</small>
          </h2>
          <p>
            An online visitor survey was carried out for the Lammas Lands between 15th December
            2015 and 18th January 2016. The most common age category was 35-44 years.
          </p>
          <p>
            The large majority (86%) of respondents lived within Godalming, with only 1% travelling more than
            15km to the site. Most visit the site 2-3 times a week or more frequently, although the most
            common individual frequency category was 2-3 times a month.
          </p>
          <p>
            The most frequently given main reason for visiting the Lammas Lands was scenery (34.8%), followed
            by rural feel (19.6%).
          </p>
        </TextArea>
        <ImageArea style={{ backgroundImage: `url(${BannerImage})` }} />
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default BannerSection;
