import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { charityTheme } from 'common/theme/charity';
import { ResetCSS } from 'common/assets/css/style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import Navbar from 'containers/Charity/Navbar';
import DrawerSection from 'containers/Charity/DrawerSection';
import BannerSection from 'containers/VisitorDemographics/BannerSection';
import CTA from 'containers/VisitorDemographics/CallToAction';
import VisitorStats from 'containers/Home/VisitorStats';
import ViewsManagement from 'containers/VisitorDemographics/ViewsManagement';
import ModeTransport from 'containers/VisitorDemographics/ModeOfTransport';
import ManagementStats from 'containers/VisitorDemographics/ManagementStats';
import Footer from 'containers/Charity/Footer';

import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from 'containers/Charity/charity.style';
import Seo from 'components/seo';

const Index = () => {
  return (
    <ThemeProvider theme={charityTheme}>
      <Fragment>
        <Seo
          title="Visitor Demographics"
          description="Information on why people visit the Lammas Lands and what they do while they are here."
        />
        <ResetCSS />
        <GlobalStyle />
        {/* Start charity wrapper section */}
        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection />
          </DrawerProvider>
          <ContentWrapper>
            <BannerSection />
            <VisitorStats showLink={false} />
            <ModeTransport />
            <ManagementStats />
            <ViewsManagement />
            <CTA />
            <Footer />
          </ContentWrapper>
        </CharityWrapper>
        {/* End of charity wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
export default Index;
