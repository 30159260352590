import React from 'react';
import { Icon } from 'react-icons-kit';
import { androidArrowForward } from 'react-icons-kit/ionicons/androidArrowForward';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import CallToActionArea, { Left, Right } from './callToAction.style';
const CallToAction = () => {
  return (
    <CallToActionArea>
      <Container className="Container">
        <Left>
          <Heading as="h3" content="Lammas Lands Visitor Survey (2016)" />
        </Left>
        <Right>
          <a href="/visitor-survey-2016.pdf" target="_blank">Download <Icon icon={androidArrowForward} /></a>
        </Right>
      </Container>
    </CallToActionArea>
  );
};

export default CallToAction;
